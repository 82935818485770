import Vue from 'vue'
import Router from 'vue-router'
import index from "@/page/match/match_list"
import info from "@/page/match/info"

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            meta:{
                title:'告诉你一个秘密的地方，APP我下了，感觉很不错，真实又靠谱，推荐给你。'
            }
        },
        {
            path: '/index',
            name: 'index',
            component: index,
            meta:{
                title:'告诉你一个秘密的地方，APP我下了，感觉很不错，真实又靠谱，推荐给你。'
            }
        }
        ,
        {
            path:'/Q6Q9U6',
            name:'Q6Q9U6',
            component:info,
            meta:{
                title:'告诉你一个秘密的地方，APP我下了，感觉很不错，真实又靠谱，推荐给你。'
            }
        }

    ]
})
